<template>
    <div>
        <b-card>
            <b-card-title class="mb-1">
                <!-- {{ title }} -->
                <span>{{ title ? title : "Stasiun ..." }}</span>
                <b-button v-if="!showLoading" @click.prevent="onRefresh()" variant="flat-light" class="float-right mt-n1 mb-2">
                    <b-spinner v-if="isRefresh" variant="info" />
                    <feather-icon v-if="!isRefresh" icon="RefreshCcwIcon" size="30" />
                </b-button>
            </b-card-title>
            <hr />
            <b-row>
                <b-col sm="12">
                    <b-tabs pills v-model="tabStep">
                        <b-tab title="Profile" active>
                            <general
                                @station="shareStation($event)"
                                @usageUpdate="usageUpdate($event)"
                                @newUpdateUsage="newUpdateUsage($event)"
                                :listUsage="list_usage"
                                @refreshRendering="refreshRendering($event)"
                                @deleteStation="onDeleteStation($event)"
                                :roles="roles"
                                :setRefresh="actionRefresh"
                                lazy
                            />
                            <!-- <general @station="shareStation($event)" @usageUpdate="usageUpdate($event)" @newUpdateUsage="newUpdateUsage($event)" :listUsage="list_usage" lazy/> -->
                        </b-tab>
                        <b-tab title="Historical Notes" lazy :disabled="!isHistoricalNotes">
                            <historical :profile="profile" :roles="roles" />
                        </b-tab>
                        <b-tab title="Geography" lazy :disabled="!isGeography">
                            <geography :profile="profile" :roles="roles" />
                        </b-tab>
                        <b-tab title="Photos" lazy :disabled="!isPhotos">
                            <photos :profile="profile" :roles="roles" />
                        </b-tab>
                        <b-tab title="Local Maps" lazy :disabled="!isLocalMaps">
                            <local-maps :profile="profile" :roles="roles" />
                        </b-tab>
                        <b-tab title="Element" lazy :disabled="!isElement">
                            <elements :profile="profile" :roles="roles" />
                        </b-tab>
                        <!-- <b-tab title="Observer" lazy> <observer /></b-tab> -->
                        <b-tab v-if="isMKG" title="MKG" lazy>
                            <usage-mkg :profile="profile" :roles="roles" @form="onRefreshForm($event)" />
                        </b-tab>
                        <b-tab v-if="isMKG" title="GTS" lazy>
                            <gts-switching :profile="profile" :roles="roles" @form="onRefreshForm($event)" />
                        </b-tab>
                        <!-- <b-tab v-if="isPosHujan" title="Pos Hujan" lazy>
              <usage-main :profile="profile" :detail="item" :roles="roles" usage="poshujan" />
            </b-tab> -->
                        <!-- baru -->

                        <!-- <b-tab
							v-for="(item, index) in list_usage"
							:key="`key-${index}`"
							:title="item.label"
							lazy>
							<usage-main :profile="profile" :detail="item"/>
						</b-tab> -->
                        <template v-for="(item, index) in list_usage">
                            <!-- <b-tab v-if="item.detail.usage_status == true && item.label == 'MKG'" :title="item.label" :key="`tabkey-${index}`" lazy>
								<usage-main :profile="profile" :detail="item" :roles="roles"/>
							</b-tab> -->
                            <b-tab v-if="item.detail.usage_status == true && item.label != 'MKG'" :title="humanize(item.label)" :key="`tabkey-${index}`" lazy>
                                <usage-main :profile="profile" :detail="item" :roles="roles" usage="lain" />
                            </b-tab>
                        </template>
                        <!-- <div v-for="(item, index) in list_usage" :key="`key-${index}`">
							<b-tab v-if="item.detail.usage_status == true" :title="item.label" :key="`tabkey-${index}`" lazy>
								<usage-main :profile="profile" :detail="item"/>
							</b-tab>
						</div> -->

                        <!--lama-->
                        <hr />

                        <!-- <b-tab v-if="pos_hujan_selected == 1" title="Pos Hujan" lazy> <usage-pos-hujan :profile="profile"/> </b-tab> -->
                        <!-- <b-tab v-if="ku_selected == 1" title="Kualitas Udara" lazy> <usage-kualitas-udara :profile="profile"/> </b-tab> -->
                        <!-- <b-tab v-if="aws_selected == 1" title="AWS Center" lazy> <usage-aws :profile="profile"/> </b-tab> -->
                        <!-- <b-tab v-if="radar_selected == 1" title="Radar" lazy> <usage-radar :profile="profile"/> </b-tab> -->
                        <!-- <b-tab v-if="accelerograph_selected == 1" title="Percepatan Tanah" lazy> <usage-accelerograph :profile="profile"/> </b-tab> -->
                        <!-- <b-tab v-if="gempa_selected == 1" title="Gempa" lazy> <usage-gempa :profile="profile"/> </b-tab> -->
                        <b-tab title="Input Reference" lazy>
                            <bmkg-entry :profile="profile" @goBack="onBack($event)" :roles="roles" :isMkg="isMKG" />
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard, BCardTitle, BCardBody, BButton, BSpinner } from "bootstrap-vue";
import General from "./General.vue";
import Historical from "./Historical.vue";
import Geography from "./Geography.vue";
import Observer from "./Observername.vue";
import Photos from "./Photos.vue";
import LocalMaps from "./LocalMaps.vue";
import Elements from "./Elements.vue";
import BmkgEntry from "./MBmkgEntry.vue";
import UsageRadar from "./UsageRadar.vue";
import UsageAws from "./UsageAws.vue";
import UsagePosHujan from "./UsagePoshujan.vue";
import UsageKualitasUdara from "./UsageKualitasUdara.vue";
import UsageAccelerograph from "./UsageAccelerograph.vue";
import UsageGempa from "./UsageGempa.vue";
import UsageMkg from "./UsageMkg.vue";
import GtsSwitching from "./GtsSwitching.vue";
import UsageMain from "./componentusage/UsageMainAll.vue";
import metadata from "@/api/metadata";
import useJwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";
import axios from "axios";

export default {
    components: {
        BTab,
        BRow,
        BCol,
        BCard,
        BCardTitle,
        BCardBody,
        BSpinner,
        BButton,
        general: General,
        BTabs,
        Historical,
        Observer,
        Geography,
        Photos,
        LocalMaps,
        Elements,
        BmkgEntry,
        UsageRadar,
        UsageAws,
        UsagePosHujan,
        UsageKualitasUdara,
        UsageAccelerograph,
        UsageGempa,
        UsageMkg,
        UsageMain,
        GtsSwitching,
    },
    // props: ["profile", "roles"], // AN duplicate di data
    data() {
        return {
            profile: null,
            tabStep: 0,
            title: "",
            mkg_selected: 0,
            bmkgentry_selected: 0,
            pos_hujan_selected: 0,
            ku_selected: 0,
            aws_selected: 0,
            radar_selected: 0,
            accelerograph_selected: 0,
            gempa_selected: 0,
            list_usage: [],
            roles: null,
            isHistoricalNotes: false,
            isGeography: false,
            isPhotos: false,
            isElement: false,
            isLocalMaps: false,
            isMKG: false,
            isPosHujan: false,
            showLoading: false,
            isRefresh: false,
            actionRefresh: 0,
            path: "",
        };
    },
    methods: {
        humanize(str) {
            var i,
                frags = str.split("_");
            for (i = 0; i < frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(" ");
        },
        refreshRendering(event) {
            if (event == "refresh") {
                // console.log("refreshRendering...", event);

                let newListUsage = [...this.list_usage];
                this.list_usage = null;
                this.list_usage = newListUsage;
            }
        },

        onDeleteStation(event) {
            console.log("onDeleteStation", event);
            this.$router.back();
        },

        newUpdateUsage(event) {
            if (event) {
                if (event.label == "MKG") {
                    this.isMKG = true;
                }
                this.list_usage.push(event);
            }
        },

        usageUpdate(event) {
            if (event) {
                (this.mkg_selected = event.is_mkg),
                    (this.bmkgentry_selected = event.is_bmkgentry),
                    (this.pos_hujan_selected = event.is_poshujan),
                    (this.ku_selected = event.is_kualitas_udara),
                    (this.aws_selected = event.is_peralatan_otomatis),
                    (this.radar_selected = event.is_radar),
                    (this.accelerograph_selected = event.is_percepatan_tanah),
                    (this.gempa_selected = event.is_gempa);
            }
        },
        shareStation(event) {
            this.profile = event;

            this.isPosHujan = this.profile.is_poshujan;
            this.title = this.profile.station_name; //+ " (" + event.station_id + ")";
            // console.log('profile', this.profile, this.title)
            this.mkg_selected = event.is_mkg;
            this.bmkgentry_selected = event.is_bmkgentry;
            this.pos_hujan_selected = event.is_poshujan;
            this.ku_selected = event.is_kualitas_udara;
            this.aws_selected = event.is_peralatan_otomatis;
            this.radar_selected = event.is_radar;
            this.accelerograph_selected = event.is_percepatan_tanah;
            this.gempa_selected = event.is_gempa;
        },
        onBack(event) {
            this.tabStep = 0;
        },

        onRefreshForm(val) {
            // console.log('Metadata Station - onRefreshForm', val)
            this.onRefresh();
        },

        onRefresh() {
            // console.log('Metadata Station - onRefresh')
            this.isRefresh = true;
            let url = helper.getBaseUrl() + this.path;
            axios.get(url, helper.getConfig()).then((response) => {
                if (response.status == 201 || response.status == 200 || response.status == 204) {
                    this.profile = response.data;
                    this.title = this.profile.station_name; //+ " (" + this.profile.station_id + ")";
                }
                this.isRefresh = false;
            });
        },

        initialize() {
            if (!this.$route.query.content || !this.$route.query.path) {
                this.$router.push({ name: "metadata" });
            }
            this.path = this.$route.query.path;
            let content = this.$route.query.content;
            this.title = content.station_name; //+ " (" + content.id + ")";
            // console.log('initialize', this.title, content)
        },

        async getListUsage() {
            try {
                let path = this.$route.query.path;
                const { data } = await metadata.listMetadataUsage(path);

                let items = data.items;
                for (var i = 0; i < items.length; i++) {
                    let usage = items[i]["BmkgSatu.metadata.station.behavusage.IBehavStationUsage"];
                    // let ph_detail = items[i]["BmkgSatu.metadata.station.behavusage.IBehavStationUsage"]
                    const row = {
                        value: usage.usage_id,
                        label: usage.usage_name,
                        status: usage.usage_status,
                        path: items[i]["@id"],
                        detail: usage,
                        // posthujan_detail: ph_detail
                    };

                    if (usage.usage_name == "MKG") {
                        this.isMKG = true;
                    }
                    this.list_usage.push(row);
                }
            } catch (error) {
                console.log(error);
                if (error.response.status != undefined && error.response.status === 401) {
                    this.$swal({
                        title: "Sesi anda telah habis.",
                        text: "Logout dan silahkan login kembali",
                        icon: "warning",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
                        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
                        localStorage.removeItem("userData");
                        this.$router.push({ name: "auth-login" });
                    });
                } else if (error.response.status != undefined && error.response.status === 404) {
                }
            }
        },

        listRoles() {
            let userLogin = JSON.parse(localStorage.getItem("userLogin"));
            let role = userLogin.role;
            let roles = {
                isRoleAdd: false,
                isRoleEdit: false,
                isRoleView: false,
                isRoleDelete: false,
            };
            if (role != "admin") {
                let myRoles = helper.getMenuRoles(userLogin.listperm);
                console.log("myRoles", myRoles);

                if (myRoles.hasOwnProperty("Station")) {
                    if (myRoles.Station.includes("Add")) {
                        roles["isRoleAdd"] = true;
                    }
                    if (myRoles.Station.includes("Modify")) {
                        roles["isRoleEdit"] = true;
                    }
                    if (myRoles.Station.includes("View")) {
                        roles["isRoleView"] = true;
                    }
                    if (myRoles.Station.includes("Delete")) {
                        roles["isRoleDelete"] = true;
                    }

                    if (myRoles.Station.includes("HistoricalNotes")) {
                        this.isHistoricalNotes = true;
                    }
                    if (myRoles.Station.includes("Geography")) {
                        this.isGeography = true;
                    }
                    if (myRoles.Station.includes("Photos")) {
                        this.isPhotos = true;
                    }
                    if (myRoles.Station.includes("Element")) {
                        this.isElement = true;
                    }
                    if (myRoles.Station.includes("LocalMaps")) {
                        this.isLocalMaps = true;
                    }
                }
            } else {
                roles["isRoleAdd"] = true;
                roles["isRoleEdit"] = true;
                roles["isRoleView"] = true;
                roles["isRoleDelete"] = true;

                this.isHistoricalNotes = true;
                this.isGeography = true;
                this.isPhotos = true;
                this.isElement = true;
                this.isLocalMaps = true;
            }
            // console.log('roles', roles)
            this.roles = roles;
        },
    },
    mounted() {
        this.initialize();
        this.getListUsage();
        this.listRoles();
    },
};
</script>
<style>
.nav-pills .nav-link {
    padding: 0.786rem 1rem;
}
</style>
